import React, { Component } from 'react'
import './NavBar.css'
import { Link } from 'react-scroll'

class NavBar extends Component {
  
  render() {
    return (
      <nav className='nav'>
        <div className="nav-container">
          <Link className='logo-container' to="Home" spy={true} smooth={true} offset={0} duration={500}>
        {/* <div className="logo-container"> */}
          <div className='logo'></div>
          <h1 className='logo-name'>
            {this.props.visible}
            <span className='tint'>S</span>umanth&nbsp;
            <span className='lastname'><span className='tint'>H</span>egde</span>
          </h1>
        {/* </div> */}
        </Link>
        <div className="primary-nav" data-visible={this.props.visible} >
          <ul className="primary-nav-items">
          <li>
            <Link to="Home" spy={true} smooth={true} offset={0} duration={1500} onClick={this.props.change} >Home</Link>
          </li>
          <li>
            <Link to="About" spy={true} smooth={true} offset={0} duration={1500} onClick={this.props.change} >About</Link>
          </li>
          <li>
            <Link to="Skills" spy={true} smooth={true} offset={0} duration={1500} onClick={this.props.change} >Skills</Link>
          </li>
          <li>
            <Link to="Education" spy={true} smooth={true} offset={10} duration={1500} onClick={this.props.change} >Education</Link>
          </li>
          <li>
            <Link to="Projects" spy={true} smooth={true} offset={20} duration={1500} onClick={this.props.change} >Projects</Link>
          </li>
          <li>
            <Link to="Contact" spy={true} smooth={true} offset={30} duration={1500} onClick={this.props.change} >Contact</Link>
          </li>
        </ul>
        </div>
        <div onClick={this.props.change} className="mobile-nav-toggle" aria-controls="primary-nav" aria-expanded={this.props.visible}>
          <div className="switch icon">
          <input type="checkbox" data-visible={this.props.visible}/>
          <div>
              <span className="line-1"></span>
              <span className="line-2"></span>
              <span className="line-3"></span>
          </div>
  </div>
        </div>
        </div>
        
      </nav>
    )

  }
}

export default NavBar