import NavBar from './Components/NavBar'
import Home from './Components/Home'
import About from './Components/About'
import Skills from './Components/Skills'
import Education from './Components/Education'
import Projects from './Components/Projects'
import Contact from './Components/Contact'
import Footer from './Components/Footer'

import ShortNavBar from './Components/ShortNavBar'
import Cards from './Components/Cards'

import "./Components/prerequisite.css"
import './App.css';
import { Routes, Route } from 'react-router-dom'
import React, { Component } from 'react'
class App extends Component {
    state = {
    visible: false
  }
  change = e => {
    console.log("Clicked");
    // console.log(this.AppVariable)
    this.setState(prevState => ({
      visible: !prevState.visible
    }));
  }
  render() {
    return (
      <div className="App" data-overlay={this.state.visible}>
        <Routes>
          <Route exact path='/' element={
            <>
            <NavBar change = {this.change} visible = {this.state.visible}/>
            <main>
                <Home />
                <About />
                <Skills />
                <Education />
                <Projects />
                <Contact />
                <Footer />
          </main>
          </>
          } />
          <Route exact path='/projects' element={
            <div style={{backgroundColor:"#fff", paddingTop:"10rem", minHeight:"100svh"}}>
            <ShortNavBar />
            <Cards />
            </div>
          } />
        </Routes>
      </div>
    )
  }
}

export default App;
