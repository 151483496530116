import React from 'react'
import { Link } from 'react-scroll'
import './Footer.css'
function Footer() {


    
  return (
    <footer id="Footer" className="footer">
        {/* <div className="footer-header">
          <h2>About <span>Me</span></h2>
        </div> */}
        <div className = "footer-container">
            <div className="row">
              <div className='col col-1'>
              <Link to="Home" spy={true} smooth={true} offset={0} duration={1500} >
                <div className="logo-container">
                  <div className='logo'></div>
                  <h1 className='logo-name'>
                    <span className='tint'>S</span>umanth&nbsp;
                    <span className='lastname'><span className='tint'>H</span>egde</span>
                  </h1>
                </div>
              </Link>
              <div className='footer-content'>
                <h4>Aspiring Full Stack Developer | Passionate Coder | Tech Geek | Still Finding 💭</h4>
                <h5>Living, Learning, & Leveling up,<br/> One day at a time.</h5>
              </div>
              </div>
              <div className='col col-3'>
                <div>
                <h3>Connect With Me</h3>
                <div className='self-links'>
                  <ul className="social-links">
                  <li><a href="https://www.linkedin.com/in/hegde-sumanth/" target={'_blank'} rel="noreferrer">
                    <div className='links'></div>
                  </a></li>
                  <li><a href="https://github.com/Dark-Milton" target={'_blank'} rel="noreferrer">
                    <div className='links'></div>
                  </a></li>
                  <li><a href="mailto:hegdesumanth8@gmail.com?subject=Hello!&body=Hi%20..." target={'_blank'} rel="noreferrer">
                    <div className='links'></div>
                  </a></li>
                  <li><a href="https://www.instagram.com/dark_milton/" target={'_blank'} rel="noreferrer">
                    <div className='links'></div>
                  </a></li>
                  <li><a href="https://api.whatsapp.com/send?phone=917349376269&text=Hii%20There!%20" target={'_blank'} rel="noreferrer">
                    <div className='links'></div>
                  </a></li>
                </ul>
              </div>
              </div>
              </div>
            </div>
            <div className='cpyrgt'>
              <h5>Copyright&copy; 2023. All Rights Reserved.</h5>
            </div>
            <div className='credits'>
              <div>
              <h5>Made with &#10084;&#65039;</h5>
              <h6>By Hegde Sumanth Shyam <span>aka Dârk么Mîltôn</span></h6>
              {/* <h5>Made with ❤</h5> */}
              </div>
            </div>
        </div>
      </footer>
  )
}

export default Footer