import React from 'react'
import './Contact.css'
import contactImg from './../../Images/Contact/contact-img1.png'
import { Bounce, Fade, Slide } from 'react-reveal'

function Contact() {


    
  return (
    <section id="Contact" className="contact">
        <div className="contact-header">
        <Bounce top>
          <h2>Get <span>In Touch</span></h2>
        </Bounce>
        </div>
        <div className = "contact-container even-columns">
          <div className='form-container'>
            <h4>Interested in working together?</h4>
            <h5><span>Drop Me a Line Or you can email me at :</span>
            <Bounce><a href="mailto:hegdesumanth8@gmail.com?subject=Hello!&body=Hi%20..." target={'_blank'} rel="noreferrer"
            ><span className='email-link'></span></a></Bounce>
            </h5>
          <form className="contact-card" 
          action="https://formspree.io/f/mvonbppy" method='POST'
          >
                    <div>
                        <label htmlFor="name" className="label">Name</label>
                        <Fade bottom>
                        <input id='name' name='name'
                            className="card-input"
                            type="text" placeholder="" required/>
                            </Fade>
                    </div>
                    <div >
                        <label htmlFor="email" className="label">Email</label>
                        <Fade bottom>
                        <input id='email' name='email'
                            className="card-input"
                            type="email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" required/>
                            </Fade>
                    </div>
                    <div >
                        <label htmlFor="message" className="label">Message</label>
                        <Fade bottom>
                        <textarea id='message' name='message'
                            className="card-input" required></textarea>
                        </Fade>
                    </div>
                    <div>
                      <Fade bottom>
                        <button type="submit"
                         className="card-button">
                            <span>Send Message</span><span className='icon'></span>
                         </button>
                      </Fade>
                    </div>
          </form>
          </div>
          <div className='contact-img-container'>
            <div className='contact-img'>
              <Slide right>
                <img src={contactImg} alt="Hegde Sumanth Shyam Contact" />
              </Slide>
            </div>
          </div>
        </div>
      </section>
  )
}

export default Contact