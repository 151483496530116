import useMediaQuery from "./useMediaQuery";

export default function useBreakpoints() {
    const breakpoints = {
        isSm: useMediaQuery("(max-width: 800px)"),
        isLg: useMediaQuery("(min-width: 800px)"),
        active: "xs"
    };
    if (breakpoints.isSm) breakpoints.active = "sm";
    if (breakpoints.isLg) breakpoints.active = "lg";
    return breakpoints;
}
