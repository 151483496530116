import React, {Component} from "react";
import Lottie from "react-lottie";

export default class GreetingLottie extends Component {
	render() {
		const animationData = this.props.animationData;
		const defaultOptions = {
			loop: true,
			autoplay: true,
			animationData: animationData,
		};

		return (
			<Lottie options={defaultOptions} isClickToPauseDisabled={true} />
		)
	}
}


// const GreetingLottie = () => {
// 	const defaultOptions = {
// 		loop: true,
// 		autoplay: true,
// 		animationData: './../../lottie/build.json',
// 	};

// 	return (
// 		<div onClick={() => null}>
// 			<Lottie options={defaultOptions} height={400} width={400} />
// 		</div>
// 	);
// };

// export default GreetingLottie;