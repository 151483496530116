import React, { Component } from 'react'
import './Cards.css'
import portfolio from './../../Images/Projects/portfolio2.png'
import manage from './../../Images/Projects/Project-Manage.png'
import classify from './../../Images/Projects/Project-Mite-Classify.png'
import isccomm from './../../Images/Projects/ISCCOMM.png'
import kspcb from './../../Images/Projects/KSPCB.png'
import aiCodeTranslator from './../../Images/Projects/AI-Code-translator1.png'
import rosetteSmartLife from './../../Images/Projects/rosette-smart-life.png'
import comingSoon from './../../Images/Projects/coming-soon.png'
import milton from './../../Images/Projects/Project-Milton.png'
import ml from './../../Images/Projects/ml.jpg'

class Cards extends Component {
  
  render() {
    return (<>
            <div className="format-container project-list cards">
            <ul class="circles">
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
            </ul>
              <div className="courses_box project-card-container">
                <div className="courses_item project-card">
                  <div className="courses-item_bg"></div>
                  <div className='content-container' style={{zIndex:2}}>
                    <h6>Featured Project</h6>
                    <h2>Personal Portfolio</h2>
                    <div className='para'>This project is a Personal Portfolio website to showcase my skills, work, education, experience and knowledge in my current domain, fully developed using various interfaces, frameworks, APIs and technologies.</div>
                    <h5 className='lang-used'>
                      <span>ReactJS&nbsp;&nbsp; HTML&nbsp;&nbsp; CSS&nbsp;&nbsp; JS</span>
                      <span className='icon-links'>
                        <a href='https://github.com/Dark-Milton/test2' target={'_blank'} rel='noreferrer' className='icon github-icon'><span style={{display: 'none'}}>github</span></a>
                        <a href='https://hegdesumanth.me' target={'_blank'} rel='noreferrer' className='icon link-icon'><span style={{display: 'none'}}>link</span></a>
                      </span>
                    </h5>
                    <div className='redirect'></div>
                  </div>
                  <div className='img-container ele-2'>
                    <img className="img" src={portfolio} alt='Hegde Sumanth Shyam Portfolio | Hegde Sumanth Shyam' />
                  </div>
                </div>
              </div>
              <div className="courses_box project-card-container">
                <div className="courses_item project-card">
                  <div className="courses-item_bg"></div>
                  <div className='content-container' style={{zIndex:2}}>
                    <h6>Featured Project</h6>
                    <h2>AI Code Translator</h2>
                    <div className='para'>This project leverages Next.js and OpenAI API to facilitate seamless conversion between programming languages. This project helped me in enhancing my capabilities.</div>
                    <h5 className='lang-used'>
                      <span>Next.js&nbsp;&nbsp; OpenAI&nbsp;&nbsp; Others</span>
                      <span className='icon-links'>
                        <a href='https://github.com/Dark-Milton/test3' target={'_blank'} rel='noreferrer' className='icon github-icon'><span style={{display: 'none'}}>github</span></a>
                        <a href='https://github.com/Dark-Milton/test3' target={'_blank'} rel='noreferrer' className='icon link-icon'><span style={{display: 'none'}}>link</span></a>
                      </span>
                    </h5>
                    <div className='redirect'></div>
                  </div>
                  <div className='img-container ele-2'>
                    <img className="img" src={aiCodeTranslator} alt='AI Code Translator | Hegde Sumanth Shyam' />
                  </div>
                </div>
              </div>
              <div className="courses_box project-card-container">
                <div className="courses_item project-card">
                  <div className="courses-item_bg"></div>
                  <div className='content-container' style={{zIndex:2}}>
                    <h6>Featured Project</h6>
                    <h2>KSPCB Project</h2>
                    <div className='para'>Enhanced experience in Full Stack Software Development by developing a comprehensive software system enhancing data management at the Karnataka State Pollution Control Board.</div>
                    <h5 className='lang-used'>
                      <span>ReactJS&nbsp;&nbsp; Node.js&nbsp;&nbsp; Express&nbsp;&nbsp; Others</span>
                    </h5>
                    <div className='redirect'></div>
                  </div>
                  <div className='img-container ele-2'>
                    <img className="img" src={kspcb} alt='KSPCB Software Project | Hegde Sumanth Shyam' />
                  </div>
                </div>
              </div>
              <div className="courses_box project-card-container">
                <div className="courses_item project-card">
                  <div className="courses-item_bg"></div>
                  <div className='content-container' style={{zIndex:2}}>
                    <h6>Featured Project</h6>
                    <h2>Rosette Smart Life</h2>
                    <div className='para'>Worked as Freelancer to design and develop a Full stack website for the startup Rosette Smart Life, encompassing product and company information, and blog integration.</div>
                    <h5 className='lang-used'>
                      <span>ReactJS&nbsp;&nbsp; Node.js&nbsp;&nbsp; Express&nbsp;&nbsp; Others</span>
                      <span className='icon-links'>
                        <a href='https://rosettesmartlife.com/' target={'_blank'} rel='noreferrer' className='icon link-icon'><span style={{display: 'none'}}>link</span></a>
                      </span>
                    </h5>
                    <div className='redirect'></div>
                  </div>
                  <div className='img-container ele-2'>
                    <img className="img" src={rosetteSmartLife} alt='Rosette Smart Life | Hegde Sumanth Shyam' />
                  </div>
                </div>
              </div>
              <div className="courses_box project-card-container">
                <div className="courses_item project-card">
                  <div className="courses-item_bg"></div>
                  <div className='content-container' style={{zIndex:2}}>
                    <h6>Featured Project</h6>
                    <h2>MITE ISCCOMM</h2>
                    <div className='para'>Worked as Freelancer to design and develop website for the International Conference on Intelligent Systems in Computing and
Communication, 2023 which was organized at MITE.</div>
                    <h5 className='lang-used'>
                      <span>HTML&nbsp;&nbsp; CSS&nbsp;&nbsp; JS&nbsp;&nbsp; Others</span>
                      <span className='icon-links'>
                        <a href='https://isccomm.in/' target={'_blank'} rel='noreferrer' className='icon link-icon'><span style={{display: 'none'}}>link</span></a>
                      </span>
                    </h5>
                    <div className='redirect'></div>
                  </div>
                  <div className='img-container ele-2'>
                    <img className="img" src={isccomm} alt='ISCCOMM MITE | Hegde Sumanth Shyam' />
                  </div>
                </div>
              </div>
              <div className="courses_box project-card-container">
                <div className="courses_item project-card">
                  <div className="courses-item_bg"></div>
                  <div className='content-container' style={{zIndex:2}}>
                    <h6>Featured Project</h6>
                    <h2>Coming Soon</h2>
                    <div className='para'>This project is a Full Stack Website developed using client-server architecture, for supporting the launch of my Portfolio website; showcases my skills in APIs, deployment, backend and frontend technology.</div>
                    <h5 className='lang-used'>
                      <span>ReactJS&nbsp;&nbsp; Node.js&nbsp;&nbsp; Express&nbsp;&nbsp; Others</span>
                      <span className='icon-links'>
                        <a href='https://github.com/Dark-Milton/test3' target={'_blank'} rel='noreferrer' className='icon github-icon'><span style={{display: 'none'}}>github</span></a>
                        <a href='https://hegde-sumanth.cyclic.app/' target={'_blank'} rel='noreferrer' className='icon link-icon'><span style={{display: 'none'}}>link</span></a>
                      </span>
                    </h5>
                    <div className='redirect'></div>
                  </div>
                  <div className='img-container ele-2'>
                    <img className="img" src={comingSoon} alt='Coming Soon Website | Hegde Sumanth Shyam' />
                  </div>
                </div>
              </div>
              <div className="courses_box project-card-container">
                <div className="courses_item project-card">
                  <div className="courses-item_bg"></div>
                  <div className='content-container' style={{zIndex:2}}>
                    <h6>Featured Project</h6>
                    <h2>Project Manage</h2>
                    <div className='para'>This project is a part of frontend challenges assigned by well-known organisation, <a rel="noreferrer" target={'_blank'} style={{textDecoration: 'none', color:'var(--clr-text-lgt-purple)', fontWeight:'500'}} href='https://www.frontendmentor.io'>Frontend Mentor</a>. This showcases my expertise in Frontend Web Development.</div>
                    <h5 className='lang-used'>
                    <span>VueJS&nbsp;&nbsp; HTML&nbsp;&nbsp; CSS&nbsp;&nbsp; JS</span>
                      <span className='icon-links'>
                        <a href='https://github.com/Dark-Milton/Project-Manage' target={'_blank'} rel='noreferrer' className='icon github-icon'><span style={{display: 'none'}}>github</span></a>
                        <a href='https://dark-milton.github.io/Project-Manage' target={'_blank'} rel='noreferrer' className='icon link-icon'><span style={{display: 'none'}}>link</span></a>
                      </span>
                    </h5>
                    <div className='redirect'></div>
                  </div>
                  <div className='img-container ele-2'>
                    <img className="img" src={manage} alt='Project Manage | Hegde Sumanth Shyam' />
                  </div>
                </div>
              </div>
              <div className="courses_box project-card-container">
                <div className="courses_item project-card">
                  <div className="courses-item_bg"></div>
                  <div className='content-container' style={{zIndex:2}}>
                    <h6>Featured Project</h6>
                    <h2>Project MITE CLassify</h2>
                    <div className='para'>This project aims at providing a unicentric access to all the study materials for 3rd semester engineering students along with access to admin page to control all the resources in this website.</div>
                    <h5 className='lang-used'>
                    <span>HTML&nbsp;&nbsp; CSS&nbsp;&nbsp; JS&nbsp;&nbsp; Firebase</span>
                      <span className='icon-links'>
                        <a href='https://github.com/Dark-Milton/Project-Mite-Classify' target={'_blank'} rel='noreferrer' className='icon github-icon'><span style={{display: 'none'}}>github</span></a>
                        <a href='https://dark-milton.github.io/Project-Mite-Classify' target={'_blank'} rel='noreferrer' className='icon link-icon'><span style={{display: 'none'}}>link</span></a>
                      </span>
                    </h5>
                    <div className='redirect'></div>
                  </div>
                  <div className='img-container ele-2'>
                    <img className="img" src={classify} alt='Project MITE Classify | Hegde Sumanth Shyam' />
                  </div>
                </div>
              </div>
              <div className="courses_box project-card-container">
                <div className="courses_item project-card">
                  <div className="courses-item_bg"></div>
                  <div className='content-container' style={{zIndex:2}}>
                    <h6>Featured Project</h6>
                    <h2>Machine Learning</h2>
                    <div className='para'>This project is a personal website to showcase my education, skills and knowledge along with solving various design challenges using frameworks and technologies while developing it.</div>
                    <h5 className='lang-used'>
                      <span>Python&nbsp;&nbsp; Google Colab&nbsp;&nbsp; Pandas&nbsp;&nbsp; NumPY</span>
                      <span className='icon-links'>
                        <a href='https://github.com/Dark-Milton/Machine-Learning' target={'_blank'} rel='noreferrer' className='icon github-icon'><span style={{display: 'none'}}>github</span></a>
                      </span>
                    </h5>
                    <div className='redirect'></div>
                  </div>
                  <div className='img-container ele-2'>
                    <img className="img" src={ml} alt='AI ML Project | Hegde Sumanth Shyam' />
                  </div>
                </div>
              </div>
              <div className="courses_box project-card-container">
                <div className="courses_item project-card">
                  <div className="courses-item_bg"></div>
                  <div className='content-container' style={{zIndex:2}}>
                    <h6>Featured Project</h6>
                    <h2>Portfolio Website(2019)</h2>
                    <div className='para'>This project is a personal website to showcase my information, skills and knowledge; developed using HTML, CSS with JavaScript functionality and BootStrap framework.</div>
                    <h5 className='lang-used'>
                      <span>HTML&nbsp;&nbsp; CSS&nbsp;&nbsp; JS&nbsp;&nbsp; BootStrap</span>
                      <span className='icon-links'>
                        <a href='https://github.com/Dark-Milton/Project-Milton' target={'_blank'} rel='noreferrer' className='icon github-icon'><span style={{display: 'none'}}>github</span></a>
                        <a href='https://dark-milton.github.io/Project-Milton/' target={'_blank'} rel='noreferrer' className='icon link-icon'><span style={{display: 'none'}}>link</span></a>
                      </span>
                    </h5>
                    <div className='redirect'></div>
                  </div>
                  <div className='img-container ele-2'>
                    <img className="img" src={milton} alt='Hegde Sumanth Shyam Portfolio 2019 | Hegde Sumanth Shyam' />
                  </div>
                </div>
              </div>
            </div>
            </>
    )

  }
}

export default Cards