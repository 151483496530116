// import React from 'react'
import './Skills.css'
import React, { useState } from 'react'
import { Bounce, Slide } from 'react-reveal';
import Dev from "../../Images/Skills/Dev.jpg"
import GreetingLottie from "../DisplayLottie";
import Build from './../../lottie/build'

// Svg
import python from "../../Images/Skills/python.svg"
import django from "../../Images/Skills/django.svg"
import javascript from "../../Images/Skills/js.svg"
import reactjs from "../../Images/Skills/react-js.svg"
import nodejs from "../../Images/Skills/nodejs.svg"
import nextjs from "../../Images/Skills/nextjs.svg"
import css from "../../Images/Skills/css3.svg"
import html from "../../Images/Skills/html-5.svg"
import bootstrap from "../../Images/Skills/bootstrap.svg"
import tailwindcss from "../../Images/Skills/tailwindcss.svg"
import firebase from "../../Images/Skills/firebase.svg"
import git from "../../Images/Skills/git.svg"
import mongodb from "../../Images/Skills/mongodb.svg"
import heroku from "../../Images/Skills/heroku.svg"
import php from "../../Images/Skills/php.png"
import java from "../../Images/Skills/java.svg"
import cpp from "../../Images/Skills/c++.svg"
import c from "../../Images/Skills/c.png"
import netlify from "../../Images/Skills/netlify.svg"
import dart from "../../Images/Skills/dart.png"
import arduino from "../../Images/Skills/arduino.png"
import mysql from "../../Images/Skills/mysql.png"

function Skills() {

  
  const custom = e => {
    const selector = document.querySelector('.custom-select');
    // if(window.innerWidth >= 420) {// override look for non mobile
      const dropDrownexists = document.querySelector(".selector-options")
      e.preventDefault();
      console.log(dropDrownexists)
      if(dropDrownexists === null) {

      const select = selector.children[0];
      const dropDown = document.createElement('ul');
      dropDown.className = "selector-options";

      [...select.children].forEach(option => {
        const dropDownOption = document.createElement('li');
        dropDownOption.textContent = option.textContent;

        dropDownOption.addEventListener('mousedown', (e) => {
          e.stopPropagation();
          select.value = option.value;
          selector.value = option.value;
          select.dispatchEvent(new Event('change'));
          selector.dispatchEvent(new Event('change'));
          output(option);
          dropDown.remove();
        });

        dropDown.appendChild(dropDownOption);   
      });
      selector.appendChild(dropDown);

      document.addEventListener('click', (e) => {
        if(!selector.contains(e.target)) {
          dropDown.remove();
        }
      });
    }
    else {
      dropDrownexists.remove()
    }
  }

  const output = (attr) => {
    setFilter(JSON.parse(attr.value));
  }

  const Skills = {
    all: [
        { logo: reactjs, name: "React JS" },
        { logo: nextjs, name: "Next.js" }, 
        { logo: tailwindcss, name: "Tailwind CSS" }, 
        { name: "Bootstrap", logo: bootstrap }, 
        { logo: javascript, name: "JavaScript" },
        { logo: css, name: "CSS3" }, 
        { logo: html, name: "HTML5" }, 
        { logo: django, name: "Django" }, 
        { logo: python, name: "Python" }, 
        { logo: nodejs, name: "Node JS" }, 
        { logo: php, name: "PHP" }, 
        { logo: firebase, name: "Firebase" }, 
        { logo: mongodb, name: "Mongo DB" },
        { logo: heroku, name: "Heroku" }, 
        { logo: netlify, name: "Netlify" },  
        { logo: c, name: "C" }, 
        { logo: cpp, name: "C++" }, 
        { logo: java, name: "Java" }, 
        { logo: dart, name: "Dart" }, 
        { logo: arduino, name: "Arduino" }, 
        { logo: mysql, name: "MySQL" }, 
        { logo: git, name: "Git" }
    ],
    frontend: [
        { logo: nextjs, name: "Next.js" }, 
        { logo: reactjs, name: "React JS" }, 
        { logo: javascript, name: "JavaScript" }, 
        { logo: css, name: "CSS3" }, 
        { logo: html, name: "HTML5" }, 
        { logo: tailwindcss, name: "Tailwind CSS" }, 
        { name: "Bootstrap", logo: bootstrap }
    ],
    backend: [
      { logo: django, name: "Django" }, 
      { logo: python, name: "Python" }, 
      { logo: nodejs, name: "Node JS" }, 
      { logo: php, name: "PHP" }, 
      { logo: firebase, name: "Firebase" }, 
      { logo: mongodb, name: "Mongo DB" }
    ],
    database: [
      { logo: mongodb, name: "Mongo DB" }, 
      { logo: firebase, name: "Firebase" }, 
      { logo: mysql, name: "MySQL" }
    ],
    programming: [
      { logo: python, name: "Python" }, 
      { logo: c, name: "C" }, 
      { logo: cpp, name: "C++" }, 
      { logo: java, name: "Java" }, 
      { logo: dart, name: "Dart" }, 
      { logo: arduino, name: "Arduino" } 
    ],
    cloud: [
      { logo: heroku, name: "Heroku" }, 
      { logo: netlify, name: "Netlify" }, 
      { logo: mongodb, name: "Mongo DB" }, 
      { logo: firebase, name: "Firebase" }
    ],
    tools: [
      { logo: git, name: "Git" },
    ]
}
const SkillCard = (props) => {
    return (

        <div className="skill-card-padding skill-card-flex skill-card-text skill-card">
            <div className="skill-card-logo">
                <img className="center h-12 w-auto select-none" alt='Hegde Sumanth Shyam Skills logo' src={props.logo} ></img>
            </div>
            <span className="p-2">{props.name}</span>
        </div>

    )
}
const [toFilter, setFilter] = useState(Skills.all)

    
  return (
    <section id="Skills" className="skills">
        <div className="skills-header">
        <Bounce top>
          <h2><span>My</span> Skills</h2>
        </Bounce>
        </div>
        <div className="skills-container skills-container-1">
          <div className="even-columns skills-columns-1">
            <div className="skills-main">
              <Slide right cascade>
              <div>
              <div className="skill-bar">
                <div className="info">
                  <p>Frontend Technologies</p>
                  <p className="percent">85%</p>
                </div>
                <div className="bar">
                  <span className="fe fe-animation"></span>
                </div>
              </div>
              <div className="skill-bar">
                <div className="info">
                  <p>Backend Technologies</p>
                  <p className="percent">75%</p>
                </div>
                <div className="bar">
                  <span className="be be-animation"></span>
                </div>
              </div>
              <div className="skill-bar">
                <div className="info">
                  <p>Database</p>
                  <p className="percent">60%</p>
                </div>
                <div className="bar">
                  <span className="db db-animation"></span>
                </div>
              </div>
              <div className="skill-bar">
                <div className="info">
                  <p>Core Programming Languages</p>
                  <p className="percent">90%</p>
                </div>
                <div className="bar">
                  <span className="cpl cpl-animation"></span>
                </div>
              </div>
            </div>
            <div>
              <div className="skill-bar">
                <div className="info">
                  <p>Problem-Solving</p>
                  <p className="percent">90%</p>
                </div>
                <div className="bar">
                  <span className="ps ps-animation"></span>
                </div>
              </div>
              <div className="skill-bar">
                <div className="info">
                  <p>Technical Skills</p>
                  <p className="percent">90%</p>
                </div>
                <div className="bar">
                  <span className="js js-animation"></span>
                </div>
              </div>
              <div className="skill-bar">
                <div className="info">
                  <p>Communication & Soft Skills</p>
                  <p className="percent">75%</p>
                </div>
                <div className="bar">
                  <span className="cass cass-animation"></span>
                </div>
              </div>
              </div>
              </Slide>
            </div>



            <div className="skills-animated-gif">
            {/* <Slide left> */}
              <GreetingLottie animationData={Build} /> 
            {/* </Slide> */}
            </div>
          </div>
        </div>




        <div className="skills-container skills-container-2">
                <div className="skills-columns-2 even-columns">
                    <div className="skills-list-container">
                        <Slide left>
                          <label  onMouseDown={custom} value="" className='custom-select'>
                            <select onChange={(event) => { setFilter(JSON.parse(event.target.value))}} className="skills-select" >
                                <option value={JSON.stringify(Skills.all)}>All</option>
                                <option value={JSON.stringify(Skills.frontend)}>Frontend</option>
                                <option value={JSON.stringify(Skills.backend)}>Backend</option>
                                <option value={JSON.stringify(Skills.cloud)}>Cloud</option>
                                <option value={JSON.stringify(Skills.database)}>Database</option>
                                <option value={JSON.stringify(Skills.programming)}>Programming</option>
                                <option value={JSON.stringify(Skills.tools)}>Tools</option>
                            </select>
                            </label>
                            <div style={{ scrollbarColor: "#4f46e5", maxHeight: "60vh" }} className="skills-list">

                                {toFilter.map((e, i) => {
                                    return <SkillCard key={i} logo={e.logo} name={e.name} />
                                })}
                            </div>
                        </Slide>
                    </div>

                    <Slide right>

                        <div className="lg:max-w-lg lg:w-lg md:w-1/2 w-5/6 sm:block hidden skills-list-img-container">

                            <img className="object-cover object-center rounded select-none" alt="hero" src={Dev} />

                        </div>
                    </Slide>
                </div>
            </div>
      </section>
      
  )
}


// const selector = document.querySelector('.custom-select');

//   selector.addEventListener('change', e => {
//     console.log('changed', e.target.value)
//   })


export default Skills