import React, { Component } from 'react'
import './ShortNavBar.css'

class ShortNavBar extends Component {
  render() {
    return (
      <nav className='shortnav'>
        <div className="nav-container">
        <a style={{textDecoration:'none'}} href='./../' className='logo-container'>
          <div className='logo'></div>
          <h1 className='logo-name'>
            <span className='tint'>S</span>umanth&nbsp;
            <span className='lastname'><span className='tint'>H</span>egde</span>
          </h1>
        </a>

        </div>
        
      </nav>
    )

  }
}

export default ShortNavBar