import React from 'react'
import portfolio from './../../Images/Projects/portfolio2.png'
import manage from './../../Images/Projects/Project-Manage.png'
import aiCodeTranslator from './../../Images/Projects/AI-Code-translator1.png'
import './Projects.css'
import { Parallax, ParallaxProvider } from "react-scroll-parallax";
import useBreakpoints from '../../Hooks/useBreakpoint';
import { Bounce, LightSpeed } from 'react-reveal'
import { Link } from 'react-router-dom'

function Projects() {
  const { isSm } = useBreakpoints();
    
  return (
    <section id="Projects" className="projects">
      <ParallaxProvider scrollAxis='vertical'>
        <div className="projects-header">
          <Bounce top>
            <h2>My <span>Projects</span></h2>
          </Bounce>
        </div>
        <div className = "projects-container">
            <div className="project-card-container">
              <div className='project-card project-card-left'>
                <Parallax className='ele-1' speed={isSm ? 0 : 10} translateY={!isSm ? [35, -60] : false} >
                  <div>
                    <h6>Featured Project</h6>
                    <h2>Personal Portfolio</h2>
                    <div className='para'>This project is a Personal Portfolio website to showcase my skills, work, education, experience and knowledge in my current domain, fully developed using various interfaces, frameworks, APIs and technologies.</div>
                    {/* <h5>ReactJS&nbsp;&nbsp; HTML&nbsp;&nbsp; CSS&nbsp;&nbsp; JS</h5> */}
                    <h5 className='lang-used'>
                      <span>ReactJS&nbsp;&nbsp; HTML&nbsp;&nbsp; CSS&nbsp;&nbsp; JS</span>
                      <span className='icon-links'>
                        <a href='https://github.com/Dark-Milton/test2' target={'_blank'} rel='noreferrer' className='icon'><span style={{display: 'none'}}>github</span></a>
                        <a href='https://hegdesumanth.me' target={'_blank'} rel='noreferrer' className='icon'><span style={{display: 'none'}}>link</span></a>
                      </span>
                    </h5>
                    <div className='redirect'></div>
                  </div>
                </Parallax>
                <div className='img-container ele-2'>
                  <img className="img" src={portfolio} alt='Hegde Sumanth Shyam Portfolio' />
                </div>
              </div>
              {/* <div className='project-card project-card-right'>
                <Parallax className='ele-1' speed={isSm ? 0 : 10} translateY={!isSm ? [40, -60] : false}>
                  <div>
                    <h6>Featured Project</h6>
                    <h2>Coming Soon</h2>
                    <div className='para para2'>This project is a Full Stack Website developed using client-server architecture, for supporting the launch of my Portfolio website; showcases my skills in APIs, deployment, backend and frontend technology.</div>
                    <h5 className='lang-used'>
                      <span>ReactJS&nbsp;&nbsp; Node.js&nbsp;&nbsp; Express&nbsp;&nbsp; Others</span>
                      <span className='icon-links'>
                        <a href='https://github.com/Dark-Milton/test3' target={'_blank'} rel='noreferrer' className='icon'><span style={{display: 'none'}}>github</span></a>
                        <a href='https://hegde-sumanth.cyclic.app/' target={'_blank'} rel='noreferrer' className='icon'><span style={{display: 'none'}}>link</span></a>
                      </span>
                    </h5>
                    <div className='redirect'></div>
                  </div>
                </Parallax>
                <div className='img-container ele-2'>
                  <img className="img" src={comingSoon} alt='Hegde Sumanth Shyam Coming Soon' />
                </div>
              </div> */}
              <div className='project-card project-card-right'>
                <Parallax className='ele-1' speed={isSm ? 0 : 10} translateY={!isSm ? [40, -60] : false}>
                  <div>
                    <h6>Featured Project</h6>
                    <h2>AI Code Translator</h2>
                    <div className='para para2'>This project leverages Next.js and OpenAI API to facilitate seamless conversion between programming languages. This project helped me gain extensive knowledge about utilizing the OpenAI API, enhancing my capabilities in both front-end development and AI integration.</div>
                    <h5 className='lang-used'>
                      <span>Next.js&nbsp;&nbsp; OpenAI API&nbsp;&nbsp; Others</span>
                      <span className='icon-links'>
                        <a href='https://github.com/Dark-Milton/test3' target={'_blank'} rel='noreferrer' className='icon'><span style={{display: 'none'}}>github</span></a>
                        <a href='https://github.com/Dark-Milton/test3' target={'_blank'} rel='noreferrer' className='icon'><span style={{display: 'none'}}>link</span></a>
                      </span>
                    </h5>
                    <div className='redirect'></div>
                  </div>
                </Parallax>
                <div className='img-container ele-2'>
                  <img className="img" src={aiCodeTranslator} alt='Hegde Sumanth Shyam Coming Soon' />
                </div>
              </div>
              <div className='project-card project-card-left'>
                <Parallax className='ele-1' speed={isSm ? 0 : 10} translateY={!isSm ? [40, -60] : false}>
                  <div>
                    <h6>Featured Project</h6>
                    <h2>Project Manage</h2>
                    <div className='para'>This project is a part of frontend challenges assigned by well-known organisation, <a rel="noreferrer" target={'_blank'} style={{textDecoration: 'none', color:'var(--clr-text-lgt-purple)', fontWeight:'500'}} href='https://www.frontendmentor.io'>Frontend Mentor</a>. This showcases my expertise in Frontend Web Development and also my commitment towards my work.</div>
                    <h5 className='lang-used'>
                      <span>VueJS&nbsp;&nbsp; HTML&nbsp;&nbsp; CSS&nbsp;&nbsp; JS</span>
                      <span className='icon-links'>
                        <a href='https://github.com/Dark-Milton/Project-Manage' target={'_blank'} rel='noreferrer' className='icon'><span style={{display: 'none'}}>github</span></a>
                        <a href='https://dark-milton.github.io/Project-Manage' target={'_blank'} rel='noreferrer' className='icon'><span style={{display: 'none'}}>link</span></a>
                      </span>
                    </h5>
                    <div className='redirect'></div>
                  </div>
                </Parallax>
                <div className='img-container ele-2'>
                  <img className="img" src={manage} alt='Hegde Sumanth Shyam Manage' />
                </div>
              </div>
            </div>
        </div>
      </ParallaxProvider>
      <LightSpeed left>
        <Link to='/projects' target={'_blank'} style={{textDecoration:'none'}}><button className='view-more'>View More<div></div></button></Link>
      </LightSpeed>
    </section>
  )
}

export default Projects