import React from 'react'
import { useCallback } from "react";
import { loadFull } from "tsparticles";
import Particles from "react-tsparticles";
import './Home.css'
import { Link } from 'react-scroll';
import { useState } from 'react';
import { useEffect } from 'react';
import { Fade } from 'react-reveal';
// import PDF from './../../Docs/HEGDE-SUMANTH-SHYAM.pdf'

function Home() {
    let [counter, setCounter] = useState(-1)
    function animateText() {
        setCounter((++counter)%3);
        setTimeout(animateText, 2500);
    }
    const particlesInit = useCallback(async engine => {
        console.log(engine);
        // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
        // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
        // starting from v2 you can add only the features you need reducing the bundle size
        await loadFull(engine);
    }, []);
    
    const particlesLoaded = useCallback(async container => {
        await console.log(container);
    }, []);
      
    useEffect(() => {
        animateText()
    }, [])
  return (
    <section className='home' id='Home'>
        <Particles 
            
            id="particles"
            init={particlesInit}
            loaded={particlesLoaded}
            options={{
                background: {
                    color: {
                        value: "#fff",
                    },
                },
                // fullScreen: {
                //     enable: false
                //     // zIndex: -1
                // },
                // style: {
                //     position: 'absolute'
                // },
                fullScreen: false,
                fpsLimit: 120,
                interactivity: {
                    events: {
                        onClick: {
                            enable: true,
                            mode: "push",
                        },
                        onHover: {
                            enable: true,
                            mode: "repulse",
                        },
                        resize: true,
                    },
                    modes: {
                        push: {
                            quantity: 8,
                        },
                        repulse: {
                            distance: 200,
                            duration: 0.4,
                        },
                    },
                },
                particles: {
                    color: {
                        value: "#7d7d7d",
                    },
                    links: {
                        color: "#7d7d7d",
                        distance: 200,
                        enable: true,
                        opacity: 1,
                        width: 1.5,
                    },
                    collisions: {
                        enable: false,
                    },
                    move: {
                        directions: "none",
                        enable: true,
                        outModes: {
                            default: "out",
                        },
                        random: false,
                        speed: 3,
                        straight: false,
                    },
                    number: {
                        density: {
                            enable: true,
                            area: 800,
                        },
                        value: 55,
                    },
                    opacity: {
                        value: 1,
                    },
                    shape: {
                        type: "circle",
                    },
                    size: {
                        value: { min: 3, max: 4 },
                    },
                },
                detectRetina: true,
            }} />
        <div className='home-container'>
          <div className='even-columns home-columns'>
            <div className='hero-text-container'>
              <div className='hero-text'>
                <Fade left cascade>
                    <div>
                        <h1>I'm <span className='hero1'>Hegde<br />Sumanth Shyam</span></h1>
                        <h3>aka <span className='hero2'>Dark Milton</span></h3>
                <h2 className='animate-text'>
                    <span className={counter===0 ? 'text-in' : null}>Full Stack Developer</span>
                    <span className={counter===1 ? 'text-in' : null}>Student</span>
                    <span className={counter===2 ? 'text-in' : null}>FreeLancer</span>
                </h2>
                    </div>
                </Fade>
                <Fade bottom>
                    <div>
                <a href={'https://drive.google.com/file/d/1aEs5C7q224jpXxz4GsCghSrizz72FwFd/view?usp=sharing'} target={'_blank'} rel='noreferrer' ><button>Download CV</button></a>
                <Link to="About" spy={true} smooth={true} offset={-20} duration={100} delay={0}><button data-type='inverted'><p>About Me</p></button></Link>
                    </div>
                </Fade>
              </div>
            </div>
            <div className='hero-img-container'>
              <div className='blob-img'></div>
              <div className='hero-img-c'><div className='hero-img'></div></div>
            </div>
          </div>
        </div> 
    </section>
  )
}

export default Home