import React from 'react'
import './Education.css'
import { Bounce, Fade } from 'react-reveal'

function Education() {

    return (
        <section id="Education" className="education">
            <div className="education-header">
                <Bounce top>
                <h2>Education<span className='exp-span'> & Experience</span></h2>
                </Bounce>
            </div>
            <div className="education-container">
                <div className="even-columns edexp-columns">
                    <div className='left-container'>
                        <div className='left'>
                            <ul>
                                <Fade bottom>
                                    <li>
                                                <div className='briefcase-icon'></div>
                                                <span className='timeline'>2020 - 2024</span>
                                                <h5>
                                                    Mangalore Institute of Technology & Engineering
                                                </h5>
                                                <p>Pursuing Bachelor Of Engineering in Computer Science & Engineering, MITE, Moodbidri, Mangalore, Karnataka</p>
                                    </li>
                                </Fade>
                                <Fade bottom>
                                    <li>
                                        <div className='briefcase-icon'></div>
                                        <span className='timeline'>2018 - 2020</span>
                                        <h5>
                                            Dr. NSAM PU College, Nitte
                                        </h5>
                                        <p>Secondary School Intermediate from Dr. NSAM PU College, Nitte, Mangalore, Karnataka</p>
                                    </li>
                                </Fade>
                                <Fade bottom>
                                    <li>
                                        <div className='briefcase-icon'></div>
                                        <span className='timeline'>2018</span>
                                        <h5>
                                            ST. Xavier's English High School, Mumbai
                                        </h5>
                                        <p>High School Matriculate from ST. Xavier's English High School, Dombivli, Mumbai, Maharashtra</p>
                                    </li>
                                </Fade>
                            </ul>
                        </div>
                    </div>
                    <div className="experience-header">
                        <Bounce top>
                        <h2>Experience</h2>
                        </Bounce>
                    </div>
                    <div className='right-container'>
                        <div className='right'>
                            <ul>
                                <Fade bottom>
                                    <li>
                                        <div className='briefcase-icon'></div>
                                        <span className='timeline'>Nov 2023 - Present</span>
                                        <h5>
                                            Full Stack Developer <span>(Freelancer)</span>
                                        </h5>
                                        <h2>
                                            Rosette Smart Life
                                        </h2>
                                        <p>Designed and developed a Full stack website for the startup Rosette Smart Life, encompassing product and company information, and blog integration to enhance online presence and user engagement.</p>
                                    </li>
                                </Fade>
                                <Fade bottom>
                                    <li>
                                        <div className='briefcase-icon'></div>
                                        <span className='timeline'>May 2023 - Sep 2023</span>
                                        <h5>
                                            Full Stack Developer Intern
                                        </h5>
                                        <h2>
                                            Karnataka State Pollution Control Board (KSPCB)
                                        </h2>
                                        <p>Enhanced experience in Full Stack Software Development by developing a comprehensive software system enhancing data management at the Karnataka State Pollution Control Board.</p>
                                    </li>
                                </Fade>
                                <Fade bottom>
                                    <li>
                                        <div className='briefcase-icon'></div>
                                        <span className='timeline'>Feb 2023 - Oct 2023</span>
                                        <h5>
                                            Web Developer <span>(Freelancer)</span>
                                        </h5>
                                        <h2>
                                            ISCCOMM 2023 Website Development, MITE
                                        </h2>
                                        <p>Designed and developed website for the International Conference on Intelligent Systems in Computing and
Communication, 2023 which was organized at MITE.</p>
                                    </li>
                                </Fade>
                                <Fade bottom>
                                    <li>
                                        <div className='briefcase-icon'></div>
                                        <span className='timeline'>Feb 2023 - April 2023</span>
                                        <h5>
                                            Full Stack Developer Intern
                                        </h5>
                                        <h2>
                                            Innomatics Research Labs
                                        </h2>
                                        <p>Designed and developed 10+ front-end and back-end applications
    utilizing React.js, Node.js, MondoDB, and Express</p>
                                    </li>
                                </Fade>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
  }
  
  export default Education