import React from 'react'
import './About.css'
import { Bounce, Slide } from 'react-reveal'
import profileImg from './../../Images/About/self-img.png'

function About() {


    
  return (
    <section id="About" className="about">
        <div className="about-header">
        <Bounce top>
        <h2>About <span>Me</span></h2>
        </Bounce>
        </div>
        <div className = "about-container">
            <Slide left>
              <div className='try'>
              {/* <div style={{width: '100%', height:'100%'}}> */}
                <div className="self-img-container" style={{ marginInline: 'auto'}}>
                  <img src={profileImg} alt='Hegde Sumanth Shyam' className="self-img" />
                </div>
              </div>
            </Slide>
          <div className="self-highlights">
            <Slide right cascade>
              <div>
            <div className="self-name">
              <h3>I'm Sumanth Hegde</h3>
              <h4>Full Stack Developer and Aspiring Software Engineer.</h4>
              <h6>"Progress is Progress; No matter how small it is."</h6>
            </div>
            <div className="self-details">
              <ul>
                <li><span>Age: </span>21</li>
                <li><span>Phone: </span>+91 7349376269</li>
              </ul>
              <ul>
                <li><span>Email: </span>hegdesumanth8@gmail.com</li>
                <li><span>Place: </span>Udupi, Karnataka - 574104</li>
              </ul>
            </div>
            <div className='self-links'>
              <ul className="social-links">
                <li><a href="https://www.linkedin.com/in/hegde-sumanth/" target={'_blank'} rel="noreferrer">
                  <div className='links'></div>
                </a></li>
                <li><a href="https://github.com/Dark-Milton" target={'_blank'} rel="noreferrer">
                  <div className='links'></div>
                </a></li>
                <li><a href="mailto:hegdesumanth8@gmail.com?subject=Hello!&body=Hi%20..." target={'_blank'} rel="noreferrer">
                  <div className='links'></div>
                </a></li>
                <li><a href="https://www.instagram.com/dark_milton/" target={'_blank'} rel="noreferrer">
                  <div className='links'></div>
                </a></li>
                <li><a href="https://api.whatsapp.com/send?phone=917349376269&text=Hii%20There!%20" target={'_blank'} rel="noreferrer">
                  <div className='links'></div>
                </a></li>
              </ul>
            </div>
          </div>
            </Slide>
            </div>
          <div className="self-summary">
            <Bounce>
              <div>
                <pre>                          </pre><p>Hii, I am <span>Hegde Sumanth Shyam,</span> a final year B.E. student in Computer Science & Engineering studying at <span>Mangalore Institute of Technology & Engineering, Mangalore.</span> I am a self-taught programmer with excellence in the core programming languages C, C++, Java, and also in Full Stack Development.
                I have a strong foundation in math, programming-logic and I am actively developing my skill set through internships and projects. I am passionate about Software Engineering and Full Stack Development.
                I thrive on challenges, and constantly set goals for myself in learning new skills, and always keep an eye on emerging technologies.
                </p>
              </div>
            </Bounce>
          </div>
        
        </div>
      </section>
  )
}

export default About